.PrivacyTerms__main-section-container{
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
}

.PrivacyTerms__title{
    text-decoration: underline;
}
.PrivacyTerms__customer-service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.PrivacyTerms__main-title{

    color: var(--quinary-color);
    font-family: 'Mitr', sans-serif;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.05rem;
    margin-bottom: 3.9rem;

}
.PrivacyTerms__description{
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.9rem; 
}
.PrivacyTerms__list {
    margin-left: 2em;
}
.PrivacyTerms__list li {
    margin-top:1em ;
    margin-bottom: 1em;
}

.PrivacyTerms__break-word{
    word-wrap: break-word;
}

.text-decoration-underline{
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    text-decoration-line: underline;
    margin-bottom: .6em;
    margin-top: .6em;
}

@media (max-width: 575.98px) {
    .PrivacyTerms__main-section-container{
        padding: 2em;
    }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .PrivacyTerms__main-section-container{
        padding: 2em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .PrivacyTerms__main-section-container{
        padding: 3em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .PrivacyTerms__main-section-container{
        padding: 4em;
    }
}

@media (min-width: 1200px) {
    .PrivacyTerms__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .PrivacyTerms__customer-service{
        width: 100%;
    }
    .PrivacyTerms__main-section-container{
        padding: 2.5em;
    }
}