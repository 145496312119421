.OurTeam__main-container{
    /* border: 3px solid red; */
    position: relative;
}
    .OurTeam__inner-container{
        /* border: 3px solid green; */
    }
        .OurTeam__container{
            /* border: 3px solid blue; */
            display: flex;
        }



        .OurTeam__background-design-1 {
            /* border: 3px solid red; */
            width: 100%;
            right: 0;
            left: 0;
            top: 5%;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            z-index: -1;
        }
        
        .OurTeam__background-design-2 {
            /* border: 3px solid rgb(0, 0, 0); */
            width: 100%;
            right: 0;
            left: 0;
            bottom: 10%;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            z-index: -1;
        }

        .OurTeam__design1, .OurTeam__design2{
            opacity: 1;
        }



/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .OurTeam__container{
        flex-direction: column;
        align-items: center;
    }
    .OurTeam__design1, .OurTeam__design2{
        opacity: .05;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .OurTeam__container{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    
    }
    
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .OurTeam__container{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    
    }

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .OurTeam__container{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    
    }
    
}

@media (min-width: 1200px) {
    .OurTeam__container{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    
    }
}

