.Cancelation__contact-form-container{
    background-color: var(--secundary-color);
    border-radius: 1.25rem;
} 

.Cancelation__main-container{
    position: relative;
}

.Cancelation__background-design-1 {
    /* border: 3px solid rgb(0, 0, 0); */
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}

.Cancelation__background-design-2 {
    /* border: 3px solid rgb(0, 0, 0); */
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}

.Cancelation__background-design-3 {
    /* border: 3px solid rgb(0, 0, 0); */
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: -1;
}



@media (max-width: 575.98px) {
    .Cancelation__contact-information-container{
        padding: 1.5em;
    }
    .Cancelation__contact-form-container{
        padding: 1em;
    }
    .Cancelation__contact-form-container{
        border-radius: 1.25rem;
        margin: 1.5em;
    } 
    .Cancelation__design1, .Cancelation__design2, .Cancelation__design3{
        opacity: .1;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Cancelation__contact-information-container{
        padding: 2em;
    }
    .Cancelation__contact-form-container{
        margin: 2em;
    }
        .Cancelation__contact-form-container{
            border-radius: 1.25rem;
            margin: 1.5em;
            padding: 2em;
        }
        .Cancelation__design1, .Cancelation__design2, .Cancelation__design3{
            opacity: .1;
        }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Cancelation__contact-information-container{
        padding: 2em;
    }
    .Cancelation__contact-form-container{
        margin-left: 10em;
        margin-right: 10em;
        margin-bottom: 2em;
        padding: 2em;
        
    }   
    .Cancelation__design1, .Cancelation__design2, .Cancelation__design3{
        opacity: .1;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Cancelation__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 10em;
    }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
            margin-left: 6em;
            margin-right: 3em;
        }
        .Cancelation__contact-form-container{
            width: 35em;
            height: 100%;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 1.5em 1.5em 1.5em 1.5em;
            /* background-color: #E6E5E5; */
            margin-left: 2em;
            margin-right: 3em;
        }
        .Cancelation__design1, .Cancelation__design2, .Cancelation__design3{
            opacity: .1;
        }
}

@media (min-width: 1200px) {
    .Cancelation__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 5em;
        margin-bottom: 10em;
    }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
        }
        .Cancelation__contact-form-container{
            width: 35em;
            height: 100%;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 4em 4em 4em 4em;
            /* background-color: #E6E5E5; */
            margin-right: 1.5em;
        }
        .Cancelation__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
            margin-left: 4em;
            margin-right: 3em;
        }

        .Cancelation__design1, .Cancelation__design2, .Cancelation__design3{
            opacity: .1;
        }
}