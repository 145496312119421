.SubscriptionPlan__main-section-container{
    position: relative;
}
.ProductDescription__main-container {
    /* border: 3px solid red; */
    display: flex;
    flex-direction: row;
    padding: 3em;
    position: relative;
 
}
.ProductDescription__inner-container{
    display: flex;
    flex-direction: row;
    position: relative;
}



.ProductDescription__top-background-picture-container{
    background-repeat: no-repeat;
    height: 8em;
    /* border: 3px solid red; */
    width: 100%;
    right: 0;
    left: 0;
    top: 1%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: -1;
}
.ProductDescription__left-background-picture-container{
    background-repeat: no-repeat;
    height: 8em;
    /* border: 3px solid red; */
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}


    .ProductDescription__explanation-container{
        /* border: 3px solid blue; */
        padding: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
        .ProductDescription__title-container{
            /* border: 3px solid green; */
            align-self: flex-start;
        }
            .ProductDescription__title-container h1{
                color: var(--quinary-color);
                font-family: 'Mitr', sans-serif;
                font-size: 4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 5rem; 
            }
        .ProductDescription__explanation-container p{
            color: #00030A;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem;
                    }
        .ProductDescription__explanation-container ol li{
            color: #00030A;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.25rem;
            margin-top: 1em;
        }
        
        
        
            

    .ProductDescription__explanation-container p{
        /* border: 2px solid black; */
        width: 100%;
    }


    .ProductDescription__img-container{ 
        /* border: 7px solid green; */
        
        
    }
        .ProductDescription__img-inner-container{
            /* border: 2px solid blue; */
            height: 35em;
            width: 40em;
        }
            .ProductDescription__img{
                /* border: 2px solid purple; */
                width: 100%;
                height: 100%;
            }

.ProductDescription__list-container ol li{
    color: #00030A;
    font-family: Lato;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.25rem;
    margin-bottom: 1.5em;
}











@media (max-width: 575.98px) {
    .ProductDescription__main-container, .ProductDescription__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        padding: 1.5em;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    font-size: 2.8rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 3.5rem; 
                    
                }
                .ProductDescription__explanation-container p{
                    color: #00030A;
                    font-family: Lato;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 2.6rem; 
                }
                
            
                
    
        .ProductDescription__explanation-container{
            /* border: 2px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: auto;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }

    .ProductDescription__top-top-picture, .ProductDescription__top-right-picture, .ProductDescription__left-picture{
        
        width: 100%;
    }
    .ProductDescription__left-picture,.ProductDescription__top-top-picture, .ProductDescription__top-right-picture{
        opacity: .2;
    }

  
    
   
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .ProductDescription__main-container, .ProductDescription__inner-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        padding: 2.2em;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    font-size: 2.6rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 3.5rem; 
                
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: Lato;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2.5rem; 
            }
            
                
    
        .ProductDescription__explanation-container{
            /* border: 2px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: auto;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
                .ProductDescription__top-right-picture, .ProductDescription__top-top-picture, .ProductDescription__left-picture{
                    /* opacity: .2; */
                }
    
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ProductDescription__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        padding: 3.5em 1em 1.5em 1em;
    }
    .ProductDescription__explanation-main-container{
        width: 100%;
    }
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            /* padding: 10em; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    font-size: 2.8rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 3.5rem; 
                  
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2.5rem; 
            }
            
                
    
        .ProductDescription__explanation-container{
            /* border: 8px solid black; */
            margin: 0;
            padding: .5em;
        }
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 80%;
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: 100%;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
                .ProductDescription__top-right-picture, .ProductDescription__left-picture{
                    opacity: .2;
                }
                
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ProductDescription__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        padding: 0;
        padding: 3.5em 1.5em 1.5em 2.5em;
    }
 
        .ProductDescription__explanation-container{
            /* border: 3px solid blue; */
            padding: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
        }
            .ProductDescription__title-container{
                /* border: 3px solid green; */
                align-self: flex-start;
                width: auto;
            }
                .ProductDescription__title-container h1{
                    font-size: 3.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 4rem; 
          
                }
            .ProductDescription__explanation-container p{
                color: #00030A;
                font-family: 'Lato', sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; 
            }
            
                
/*     
            .SubscriptionCard__card-container{
                border: 8px solid rgb(0, 0, 0);
                width: 22em;
                height: 25em;
            } */
    
    
        .ProductDescription__img-container{ 
            /* border: 7px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            
            
        }
            .ProductDescription__img-inner-container{
                /* border: 2px solid blue; */
                height: auto;
                width: 100%;
            }
                .ProductDescription__img{
                    /* border: 2px solid purple; */
                    width: 100%;
                    height: 100%;
                }
                
}
 

@media (min-width: 1200px) {
    .ProductDescription__explanation-container{
        /* border: 3px solid blue; */
        padding: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ProductDescription__img-container{ 
        /* border: 7px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: .8em;
          
    }
    .ProductDescription__title-container h1{
        padding-left: .5em;
        font-size: 4rem;
        font-style: normal; 
        font-weight: 500;
        line-height: 4rem; 
    }
    .ProductDescription__img-inner-container{
        /* border: 2px solid blue; */
        height: auto;
        width: 30em;
    }
        .ProductDescription__img{
            /* border: 2px solid purple; */
            width: 100%;
            height: 100%;
        }

        .ProductDescription__main-container{
            /* border: 6px solid blue; */
            padding: 0;
            padding-right: 3em;
            padding-left: 3em;
        }
        .ProductDescription__inner-container{
            padding-top: 3em;
        }
        .Benefit__main-contiainer {
            padding-left: 4em;
            padding-right: 4em;
            /* border: 15px solid red; */
        }
   
}
@media (min-width: 1500px) {
    .ProductDescription__main-container{
        /* border: 6px solid blue; */
        padding: 0;
        padding-right: 8em;
        padding-left: 8em;
    }
    

}

@media (min-width: 2000px) {
    .ProductDescription__main-container{
        /* border: 6px solid blue; */
        padding: 0;
        padding-right: 15em;
        padding-left: 15em;
    }

}