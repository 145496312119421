.Refund__main-title{
    color: var(--quinary-color);
    text-align: center;
    font-family: 'Mitr', sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 5 00;
    line-height: normal;
    word-wrap: break-word;
}

.Refund__section-description{
    margin-top: 2em;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; 

}
.Refund__main-section-container{
    padding: 5em;
}
.Refund__customer-service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}

.Refund__ol-list{
    margin-left: 2em;
}
    .Refund__ol-list li {
        margin-bottom: .6em;
    }


@media (max-width: 575.98px) {
    .Refund__main-section-container{
        padding: 2em;
    }
    .Refund__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.3rem;
    }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .Refund__main-section-container{
        padding: 2em;
    }
    .Refund__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.3rem;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Refund__main-section-container{
        padding: 2em;
    }
    .Refund__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.3rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
    .Refund__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .Refund__customer-service{
        width: 100%
    }
}