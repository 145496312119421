
.PaymentInfo__title{
    color: var(--quinary-color);
    text-align: center;
    font-family: "Mitr", sans-serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.375rem; /* 145.833% */
    letter-spacing: -0.09219rem;
}

.PaymentInfo__payment-form-container{
    /* border: 3px solid red; */
    margin-bottom: 2em;
}
.PaymentInfo__main-section{
    position: relative;
}


.PaymentInfo__background-design-1 {
    /* border: 3px solid red; */
    width: 100%;
    right: 0;
    left: 0;
    top: 5%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: -1;
}

.PaymentInfo__background-design-2 {
    /* border: 3px solid rgb(0, 0, 0); */
    width: 100%;
    right: 0;
    left: 0;
    top: 10%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}

.PaymentInfo__background-design-3 {
    /* border: 3px solid rgb(0, 255, 174); */
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: -1;
}



@media (max-width: 575.98px) {
    .PaymentInfo__main-section{
        /* border: 9px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .PaymentInfo__main-title-container{
        /* border: 6px solid red; */
    }
        .PaymentInfo__title{
            /* border: 3px solid yellow; */
            font-size:3rem;
            line-height: 2.7rem;

        }
        .PaymentInfo__payment-form-container{
            width: 100%;
        }
 
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .PaymentInfo__title{
        /* border: 3px solid yellow; */
        font-size:3rem;
        line-height: 3rem;
    }
    .PaymentInfo__payment-form-container{
        /* border: 3px solid blue; */
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
}