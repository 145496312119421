.NavegationInfo__section-container{
    /* border: 2px solid red; */
    position: relative;
}
    .NavegationInfo__information{
        /* border: 3px solid green; */
        padding: 1em;
    }
    .NavegationInfo__information span{
        color: #000;
        font-family: 'Lato', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .NavegationInfo__background-design1{
        /* border: 3px solid red; */
        width: 100%;
        height: 6em;
        right: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: -1;
    }
    .NavegationInfo__background-design1 div{
        margin-right: 3em;
    }
   
    .NavegationInfo__design1{
        width: 100%;
        height: 6em;

    }
    .NavegationInfo__design1{
        opacity: .3;
    }
/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
   
}


@media (min-width: 576px) and (max-width: 767.98px) {
}


@media (min-width: 768px) and (max-width: 991.98px) {
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
}