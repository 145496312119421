@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root{
  --primary-color : #ffffff;
  --secundary-color : #F25C05;
  --tertiary-color:  #e66931;
  --quaternary-color: #F2EBD5;
  --quinary-color: #64078C;
  --senary-color : #360259;

}
html {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
}

.solitary-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5em;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.form-select{
  padding: .65em !important;
}