
.SectionInfo__main-section-container{
    /* padding-left: 2em;
    padding-right: 2em; */
    /* margin-top: 1.5em; */
    margin-bottom: 2em;
    /* border: 3px solid green; */
}
    .SectionInfo__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border: 3px solid red; */
        padding: 1em;
    }
        .SectionInfo__title{
            color: #FFF;
            text-align: center;
            font-family: 'Mitr', sans-serif;
            font-size: 3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.05rem; 
            width: auto;
            padding: 0;
        }


@media (max-width: 575.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
    }
    .SectionInfo__title{
        color: #ffffff;
        text-align: center;
        font-size: 2.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.5rem;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionInfo__title{
        line-height: 3.5rem;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionInfo__title{
        line-height: 3.5rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionInfo__title{
        line-height: 2.5rem;
    }

}

@media (min-width: 1200px) {
    .SectionInfo__title{
        line-height: 2.5rem;
    }
    
.SectionInfo__title-container{
   padding: 2em;
}
}
        