.Benefit__main-contiainer{
    /* border: 6px solid red; */
    margin-bottom: 6em;
    
}   
    .Benefit__inner-contianer{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        margin-top: 4em;
    }

/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
  
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 6em;
        padding: 0;
    }
   
    /*   */
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Benefit__inner-contianer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 6em;
    }
}

@media (min-width: 1200px ) and ( max-width: 1499.98px) {
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6em;
        padding: 0;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            /* border: 3px solid green; */
        }

}

@media (min-width: 1500px){
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 90em;
        }

}

@media (min-width: 1900px){
    .Benefit__main-contiainer{
        /* border: 6px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;

    }   
        .Benefit__inner-contianer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 100em;
        }
}