.Faq__main-container{
    /* border: 3px solid blue; */
    padding: 0;
    margin: 0;
    position: relative;
}
    .Faq__faqs-container{
        /* border: 10px solid green; */
        /* width: auto; */
        /* width: 2em; */
        width: 100%;
   
    }
    .Faq__main-title{
        width: 100%;
        margin-top: 6em;
        margin-bottom: 2em;
    }
    .Faq__main-title h1{
        text-align: center;
        color: #00030A;
        text-align: center;
        font-family: Montserrat;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; /* 35% */
        text-transform: uppercase;
    }
        .Faq__faqs{
            /* border: 3px solid black; */
            /* display: flex;
            width: auto; */
            width: 30em
            
        }

        .Faq__faqs.columns {
             display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;  
           
          }
          
          .Faq__faqs.columns > * {
            width: auto; 
          }
          .Faq__main-container__main-title{
            color: #00030A;
            text-align: center;
            font-family: Montserrat;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem; 
            text-transform: uppercase;
            /* border: 2px solid red; */
            padding: .5em;
        }
        .Faq__customer-service-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2.5em;
            margin-bottom: 1.5em;
        }


        .Faq__background-design-1 {
            /* border: 3px solid red; */
            width: 100%;
            right: 0;
            left: 0;
            top: 5%;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            z-index: -1;
            
        }   
        
        
        .Faq__background-design-2 {
            /* border: 3px solid rgb(0, 0, 0); */
            width: 100%;
            right: 0;
            left: 0;
            top: 50%;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            z-index: -1;
           
        }

        .Faq__background-design-3 {
            /* border: 3px solid rgb(0, 0, 0); */
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            z-index: -1;
        
        }

        .Faq__design1, .Faq__design2, .Faq__design3{
            opacity: .5;

        }

@media (max-width: 575.98px) {
    .Faq__main-container{
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .Faq__faqs-container{
            /* width: auto; */
            /* width: 2em; */
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
       
        }
            .Faq__faqs{
                /* display: flex;
                width: auto; */
                width: 30em;
                display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
                
                
            }
    
            .Faq__faqs.columns {
                 display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;  
               
              }
              
              .Faq__faqs.columns > * {
                width: auto; 
              }


    .Faq__main-container__main-title{
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    }

    .Faq__design1, .Faq__design2, .Faq__design3{
        opacity: .2;

    }
   
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .Faq__main-container__main-title{
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    } 
    .Faq__design1, .Faq__design2, .Faq__design3{
        opacity: .1;

    }
    .Faq__faqs{
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
         
     }

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Faq__main-container__main-title{
        font-size: 1.9rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    }
    .Faq__design1, .Faq__design2, .Faq__design3{
        opacity: .2;

    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Faq__main-container__main-title{
        font-size: 1.9rem;
    }
    .Faq__design1, .Faq__design2, .Faq__design3{
        opacity: .2;

    }
}

@media (min-width: 1200px) {
    .Faq__main-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4em;

    }
}
          